import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const solidGateUpdateMethodIntent = createAsyncThunk(
  "solidGateUpdateMethodIntent",
  async (data) => {
    try {
      const response = await AxiosInstance.get(
        `/subscription/solidgate-create-payment-method-intent`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const solidGateUpdateMethodIntentSlice = createSlice({
  name: "solidGateUpdateMethodIntent",
  initialState: {
    isLoading: false,
    solidGateUpdateMethodIntent: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [solidGateUpdateMethodIntent.pending]: (state) => {
      state.isLoading = true;
    },
    [solidGateUpdateMethodIntent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.solidGateUpdateMethodIntent = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [solidGateUpdateMethodIntent.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default solidGateUpdateMethodIntentSlice.reducer;
