import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";

export const solidGateIntentV2Create = createAsyncThunk(
  "solidGateIntentV2Create",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/v2/solidgate-payment-intent-create`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const solidGateIntentV2CreateSlice = createSlice({
  name: "solidGateIntentV2Create",
  initialState: {
    isLoading: false,
    solidGateIntentV2Create: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [solidGateIntentV2Create.pending]: (state) => {
      state.isLoading = true;
    },
    [solidGateIntentV2Create.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.solidGateIntentV2Create = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [solidGateIntentV2Create.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default solidGateIntentV2CreateSlice.reducer;
